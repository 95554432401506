.settings_main {
    h2 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: #3c3c3c;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 35px;
    }
    h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #3c3c3c;
        text-align: left;
        text-transform: uppercase;
    }
    .settings_part {
        width: 30%;
        min-width: 330px;
        ul {
            margin: 5px;
            padding: 8px;
            text-decoration: none;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 40px;
                border: 1px solid #d7d7d7;
                margin: 0;
                padding: 0;
                text-decoration: none;
                list-style: none;
                padding: 12px 16px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                text-align: left;
                h4 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: left;
                    color: #757575;
                }
                span {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    text-align: left;
                    color: #757575;
                }
                &:hover {
                    h4,
                    span {
                        color: #269ace;
                    }
                }
            }
            a {
                &:not(:last-child) {
                    li {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}