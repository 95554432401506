.common_page_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .common_page_text {
        text-align: left;
        h2 {
            margin-bottom: 10px;
            margin-top: 0;
            font-weight: 500;
            color: #2940b8;
            font-weight: 600;
            font-size: 28px;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: flex-start;
            svg {
                width: 36px;
                height: 36px;
            }
        }
        span {
            font-weight: normal;
            color: #999;
            font-size: 16px;
        }
    }
}
.common_modal_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
}

.button_success {
    background-color: #4caf50 !important;
    border: 1px solid #4caf50;
    color: #fff;
    // font-weight: 500;
    &:hover {
        border-color: #56ca5a !important;
        background-color: #56ca5a !important;
        color: #fff !important;
    }
    &:focus {
        border-color: #56ca5a !important;
        background-color: #56ca5a !important;
        color: #fff !important;
    }
}
.ant-popconfirm-buttons {
    display: flex !important;
}
