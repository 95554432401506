.image_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.image_container {
    min-width: 240px;
    min-height: 320px;
    border: 1px solid #0000001e;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 0 16px 0 16px;
}
.common{
    border: 2px solid rgb(65, 155, 245);
    border-radius: 1%;
    margin: 5px;
}
.common .pdf{
    width: 100%;
    height: 100%;
}
.common .pdf .title{
    width: 100%;
    height: 40px;
    background: rgb(65, 155, 245);
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-left: 14px;
}
.common .pdf .text{
    font-weight: bold;
    font-size: 16px;
    padding: 9px;
    
}
.common .pdf .text span{
    font-weight: 500;
}