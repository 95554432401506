.header {
    height: 70px;
    width: 100%;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.userInfo{
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.userPhoto{
    width: 56px;
    height: 56px;
}
.userPhoto img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}