.main_login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    .login_left,
    .login_right {
        width: 50%;
        height: 100vh;
        max-height: 100%;
    }
    .login_left {
        background-color: #222222;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
            width: 300px;
        }
    }
    .login_right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 400px;
        height: 460px;
        box-shadow: 0px 5px 80px 0px #e4e8f0;
        // height: 450px;
        background-color: #fff;
        img {
            width: 100%;
        }
        .login_form {
            width: 100%;
            padding: 50px 40px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            gap: 50px;
            h4 {
                font-size: 32px;
                margin: 0;
            }
            .form_area {
                width: 100%;
                input {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
                .custom_password_item {
                    input {
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                }
                .login_form_button {
                    width: 100%;
                    border-radius: 2px;
                    height: 40px;
                }
            }
        }
    }
}
