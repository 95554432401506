body {
    font-family: Arial, Helvetica, sans-serif;
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loading img {
    margin: 10px;
}

* {
    box-sizing: border-box;
}
.indep-icon {
    font-size: 40px !important;
}
/* button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}  */
a {
    text-decoration: none;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9fafb;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
    display: flex;
    text-align: center;
    width: 100%;
    min-height: 100vh;
}
.main_left {
    height: 100%;
    position: fixed;
    z-index: 4;
}
.main_right {
    width: calc(100% - 66px);
    height: 100%;
    margin-left: 66px;
}
li {
    list-style: none;
}

.center_items {
    display: flex;
    align-items: center;
    justify-content: center;
}
.end_items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.start_items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.space_between_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gap_20 {
    gap: 20px;
}
.gap_10 {
    gap: 10px;
}
.wrap {
    flex-wrap: wrap;
}

/* Global Stylesheet */

/* Width and background of the scrollbar */
::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar here */
    height: 5px; /* For horizontal scrollbars */
    background-color: #f5f5f5;
}

/* Style for the thumb (the scrollable element) */
::-webkit-scrollbar-thumb {
    background: #43488d;
    border-radius: 10px; /* Optional: if you want rounded corners */
}

.subTable td {
    background: #f1f1f1 !important;
}
.approved-row {
    background-color: #e6f7e6;
}

.approved-row td {
    background-color: #e6f7e6 !important;
    border-top: 1px solid rgba(255, 0, 0, 0.165) !important;
    border-bottom: 1px solid rgba(255, 0, 0, 0.165) !important;
    &:first-child {
        border-left: 1px solid rgba(255, 0, 0, 0.165) !important;
    }
    &:last-child {
        border-right: 1px solid rgba(255, 0, 0, 0.165) !important;
    }
}

.not_readed_row td {
    font-weight: bold !important;
}

.new_status {
    background-color: #dfcd33;
    color: #fff;
    padding: 2px 6px;
    border-radius: 10px;
}
.approved_status {
    background-color: #4caf50;
    color: #fff;
    padding: 2px 6px;
    border-radius: 10px;
}
.rejected_status {
    background-color: #f44336;
    color: #fff;
    padding: 2px 6px;
    border-radius: 10px;
}
.completed_status {
    background-color: #d3d3d3;
    color: #fff;
    padding: 2px 6px;
    border-radius: 10px;
}
.default_status {
    border: 1px solid #bab9b9;
    padding: 2px 6px;
    border-radius: 10px;
}
