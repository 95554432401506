* {
  box-sizing: border-box;
  /* font-size: 16px; */
}
.indep-icon {
  font-size: 40px !important;
}
/* button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}  */
a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafb;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
  display: flex;
  text-align: center;
  width: 100%;
  min-height: 100vh;
}
.main_left {
  height: 100%;
  width: 64px;
  position: fixed;
  z-index: 4;
  background: #43488d;
}
.main_right {
  width: calc(100% - 66px);
  height: 100%;
  margin-left: 66px;
}
