.sidebar_button {
    // height: 95px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid #6a97af;
    max-height: 66px;
    span {
        cursor: pointer;
        color: #fff;
        &::before {
            font-size: 24px;
        }
    }
}

.sidebar_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 66px);
}

.sidebar_close {
    width: 66px;
    background: #43488d;
    height: 100%;
    min-height: 100vh;
    animation-name: close_sidebar;
    animation-delay: 0ms;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.sidebar_active {
    background-color: #2b82a6;
}

.exit_modal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
        font-weight: normal;
        font-size: 16px;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

/* ===== NEW ===== */
.sidebar_list {
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: 0.1s ease-out;
    li {
        margin: 0;
        list-style: none;
        transition: 0.1s ease-out;
        position: relative;
        a {
            color: white;
            width: 100%;
            display: block;
        }
    }
    .sidebar_list_main_item {
        overflow: hidden;
        width: 100%;
        display: flex;
        padding: 12px 20px;
        justify-content: space-between;
        color: #fff;
        transition: 0.1s ease-out;
        &:hover {
            background-color: #195975;
            transition: 0.1s ease-out;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
    .sidebar_list_main_item_active {
        background-color: #195975;
        font-weight: 500;
    }
}

.sidebar_list_dropdown {
    max-height: 0;
    overflow: hidden;
    transition: 0.1s ease-out;
    padding: 0;
    ul {
        padding: 0;
        li {
            text-align: left;
            color: #fff;
            padding: 10px 20px 10px 54px;
            white-space: nowrap;
            &:hover {
                background-color: #195975;
                transition: 0.1s ease-out;
            }
        }
    }
}
.sidebar_list_dropdown_active {
    background-color: #195975;
    font-weight: 500;
}
.sidebar_list_dropdown_opened {
    position: absolute;
    background: #2d6e90;
    max-height: 0;
    overflow: hidden;
    top: 0;
    left: 66px;
    ul {
        padding: 0;
        width: 220px;
        li {
            text-align: left;
            color: #fff;
            padding: 12px 20px 12px 16px;
            width: max-content;
            min-width: 100%;
            height: 48px;
            white-space: nowrap;
            &:hover {
                background-color: #195975;
                transition: 0.1s ease-out;
            }
        }
    }
}

.show_list {
    max-height: none;
}
.sidebar_list_bottom {
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: 0.1s ease-out;
    li {
        margin: 0;
        list-style: none;
        transition: 0.1s ease-out;
    }
    .sidebar_list_main_item {
        overflow: hidden;
        width: 100%;
        display: flex;
        padding: 12px 20px;
        justify-content: space-between;
        color: #fff;
        transition: 0.1s ease-out;
        &:hover {
            background-color: #195975;
            transition: 0.1s ease-out;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
}

.sidebar_list_icon_text {
    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
    }
    span {
        white-space: nowrap;
    }
}
