.main_page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 30px;
    flex-wrap: wrap;
    // max-width: 1400px;
    .main_page_card {
        width: 325px;
        max-width: 400px;
        height: 70px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #e8e8e8;
        gap: 16px;
        border-radius: 10px;
        color: #212121;
        padding: 20px 35px;
        // overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: max-width 0.3s ease-in-out;
        position: relative;
        .main_page_card_icon {
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 30px;
                height: 30px;
            }
            span {
                font-size: 40px;
                
            }
        }
        .main_page_card_text {
            font-size: 20px;
            font-weight: 600;/* Keep the text on a single line */
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
            text-align: left;
        }
        &:hover {
            border: 2px solid #2940b8;
            color: #2940b8;
            cursor: pointer;
            max-width: none; /* Removes the max-width constraint */
            white-space: normal;
            // width: auto;
        }
    }
}
.main_page_card[data-tooltip]:hover:after {
    content: attr(data-tooltip);
    position: absolute;
    top: 54px; /* adjust this value based on your layout */
    left: 0;
    z-index: 1;
    // white-space: nowrap;
    background-color: #212121;
    border: 1px solid gray;
    color: white;
    padding: 5px;
    border-radius: 10px;
    visibility: visible;
    width: calc(100% - 10px);
}